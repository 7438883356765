<template>
  <div slot="header" class="row align-items-center justify-content-between mb-2">
    <div class="float-left">
      <h4 class="text-uppercase ls-1 mb-0 ml-4 font-weight-700 text-uppercase">

        <template v-if="screen==='PatientDetail'">
          Notes (Internal)
        </template>

        <template v-else-if="screen==='NutritionReport'">
          Nutrition Notes (Internal)
        </template>

        <template v-else-if="screen==='Checklists'">
          Checklist And Pipeline Notes (Internal)
        </template>

      </h4>
    </div>
    <div class="float-right mr-4" v-if="(noteType !== 'pipeline_notes' && noteType !== 'checklist_notes' && noteType !== 'event_attended_note')
    || (screen === 'Checklists' && noteType == 'checklist_notes')">
      <button class="add-note-btn px-2 py-1" @click="$emit('openAddNotesModal')">
        <img alt="Image placeholder" class="mt--1" src="/img/icons/buttons/add.svg">
        Add Notes
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotesHeader",
  props: ['screen','noteType']
}
</script>

<style scoped>
.add-note-btn {
  border: 1px solid #2F597A;
  background-color: transparent;
  color: #2F597A;
  border-radius: 4px;
}
</style>
